<template>
  <el-form :model="forms" :rules="rules" ref="forms" label-width="100px" size="large">
    <el-row>
      <el-col :span="24">
        <el-form-item label="流程名称">
          <el-input v-model="configer.flowName" disabled></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="当前节点">
          <el-input v-model="currentNode.label" disabled style="max-width: 180px"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="下个节点">
          <el-input v-model="nextNode.label" disabled style="max-width:180px"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="待办人">
          <el-input v-model="configer.operatorName" disabled style="max-width:180px"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="操作人">
          <el-input v-model="$store.getters.getUserInfo.userName" disabled style="max-width:180px"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="审批操作">
          <el-radio-group v-model="status">
            <el-radio label="10" border>{{ (configer.status == 0 ? "发起审核" : "同意") }}</el-radio>
            <el-radio label="-10" border v-if="configer.status != 0">退回</el-radio>
            <el-radio label="-50" border v-if="configer.status != 0">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="审批意见">
          <el-input v-model="remark" type="textarea" placeholder="请输入审批的意见" :rows="3" style="max-width:95%"></el-input>
        </el-form-item>
      </el-col>
      <!-- 下节点是结束节点的时候不需要选择审批人 -->
      <el-col :span="24" v-if="personVisible">
        <el-form-item label="审核人">
          <!-- <el-input v-model="forms.operatorName" placeholder="请选择节点的审批人" autocomplete="off"
            style="max-width: 250px"></el-input> -->
          <el-autocomplete style="width:120px;" v-model="forms.operatorName" :fetch-suggestions="searchAsync"
            placeholder="选择审批人" @select="chooseHandle" :disabled="fixOperator" v-if="!fixOperator">
          </el-autocomplete>
          <div class="personBox">
            <el-tag :closable="!fixOperator" v-for="item in chooseData" :key="item" @close="closeHandle(item)">
              {{ item.realName }}
            </el-tag>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>


<script>
import { fetchConfigerByCode, getSuggestUser } from "@/api/workflow";
import { fetchUsers } from "@/api/sys";
export default {
  name: "auditer",
  components: {},
  props: {
    instance: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      configer: {},
      flowData: {},
      forms: {},
      rules: {
        // operatorId: [
        //   { required: true, message: "请选择节点的审批人", trigger: "blur" },
        // ],
      },
      chooseData: [],

      currentNode: {},
      nextNode: {},
      status: '', //页面上的操作状态
      remark: '同意',

    };
  },
  methods: {
    getAuditor() {
      if (this.configer.status != 0 && (this.status) == '') {
        this.$message({ type: "warning", message: "请选择该申请单的审批操作", });
        return;
      }
      if ((this.remark || '').length == 0) {
        this.$message({ type: "warning", message: "请输入该申请单的审批意见", });
        return;
      }
      if ((this.status == '-10' || this.status == '-50') && this.remark == '同意') {
        this.$message({ type: "warning", message: "请输入该申请单操作的具体意见，如果退回或者拒绝审批其意见不能为同意", });
        return;
      }


      // //拒绝或者退回操作的时候
      // if (this.status == '-10' || this.status == '-50') {
      //   if ((this.remark || '').length == 0) {
      //     this.$message({ type: "warning", message: "请输入该申请单退回或拒绝的原因", });
      //     return;
      //   }
      // }
      // //下个节点是结束时候不需要选择审批人
      // if (this.status == '10' && this.nextNode.clazz == 'end') {
      //   this.$message({ type: "warning", message: "请输入该申请单退回或拒绝的原因", });
      //   return;
      // }

      let notes = { remark: this.remark, status: (this.status || 0) };

      return { operator: this.chooseData, currentNode: this.currentNode, nextNode: this.nextNode, notes: notes };
    },
    //搜索（请求后台数据）
    searchAsync(queryString, callback) {
      //console.log(queryString, callback);
      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "realName", searchable: true },
          { prop: "gender" },
        ],
        filters: [],
        keyword: queryString,
      };
      search.filters.push({ name: "isEnabled", value: '1', operate: 0, });
      fetchUsers(search).then((res) => {
        console.log(res);
        //res.data.data
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.realName,
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    //选择选中事件
    chooseHandle(item) {
      console.log(item);
      if (this.chooseData.filter(res => res.id == item.id).length == 0) {
        //this.forms.operatorId = item.id;
        //this.forms.operatorName = item.realName;
        this.chooseData.push(item);
      }
      this.forms.operatorName = '';
    },
    closeHandle(item) {
      this.chooseData = this.chooseData.filter(res => res.id != item.id);
    },

    getNextNode(flowData, currNodeId) {
      //let currNode = this.flowData.nodes.filter(res => res.id == currNodeId)[0];
      let currEdge = flowData.edges.filter(res => res.source == currNodeId)[0];
      return flowData.nodes.filter(res => res.id == currEdge.target)[0];
    },
    //获取这个节点的 建议审批人
    getSuggestUser() {
      if (this.nextNode && this.nextNode.id && (!this.nextNode.operator || this.nextNode.operator.length == 0)) {
        getSuggestUser(this.instance.flowCode, this.nextNode.id).then((res) => {
          if (!res || !res.data || res.code != 200) return;
          this.chooseData = res.data;
        });
      }
    }
  },
  created() { },
  mounted() {
    //console.log(this.instance);
    if (!this.instance.id) { //新的流程还未发起
      fetchConfigerByCode(this.instance.flowCode).then((res) => {
        this.configer = res.data;
        this.configer.status = '0'; //默认草稿
        this.flowData = JSON.parse(res.data.flowData);
        this.currentNode = this.flowData.nodes.filter(res => res.clazz == 'start')[0];
        this.nextNode = this.getNextNode(this.flowData, this.currentNode.id);
        this.getSuggestUser();
      });
    } else { //已经保存起来的流程
      this.configer = this.instance;
      this.status = '10'; //默认同意
      this.flowData = JSON.parse(this.instance.flowData);
      this.currentNode = this.flowData.nodes.filter(res => res.id == this.instance.nodeID)[0];
      this.nextNode = this.getNextNode(this.flowData, this.currentNode.id);
      this.getSuggestUser();
    }
  },
  computed: {
    personVisible() {
      //默认也不展示
      if (this.status == '' && this.configer.status != '0')
        return false;
      //如果正常审批下节点是结束节点
      if (this.status == '10' && this.nextNode.clazz == 'end')
        return false;
      if (this.status == '-10' || this.status == '-50')
        return false;

      return true;
    },
    fixOperator() {
      if (this.nextNode && this.nextNode.operator && this.nextNode.operator.length > 0) {
        this.chooseData = this.nextNode.operator;
        return true;
      }
      return false;
    }
  }
};
</script>
<style scoped>
.personBox {
  margin-left: 10px;
}

.personBox span {
  margin: 0 5px;
}
</style>